<script>
import Vue from "vue";
import store from "@/state/store";

import { required, email } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
  AccountStatus,
  notificationMethods, SessionLoginState
} from "@/state/helpers";



/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Account setup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      submitted: false,
	    email_verified_timeout: true,
	    email_verified_counter: 60,
	    timeout: null,

    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
	destroyed() {
		if(this.timeout) clearInterval(this.timeout);
	},
	mounted() {
		this.timeoutResend(60);
	},
  sockets: {
    email_verified() {
      const router = this.$router; // Promise hell...
      this.$swal({
        icon: 'success',
        title: '',
        text: this.$t('setup.email.verify.success'),
        confirmButtonText: this.$t('generic.continue')
      }).then(function() {
        router.push({name: "setup_account"}).catch((error) => {});
      });
    }
  },
  methods: {
    ...notificationMethods,
	  timeoutResend(duration) {
		  if(this.timeout) clearInterval(this.timeout);
			this.email_verified_timeout = true;
			this.email_verified_counter = duration;
		  this.timeout = setInterval(this.processTimeout, 1000);
	  },
	  processTimeout() {
			this.email_verified_counter--;
			if(this.email_verified_counter <= 0) {
				clearInterval(this.timeout);
				this.email_verified_timeout = false;
			}
	  },
    handle_fail_and_reset() {
      this.submitted = false;
      this.$swal( {
        icon: 'error',
        title: this.$t('error.server.generic.title'),
        text: this.$t('error.server.generic.message')
      });
    },
    handle_rate_limit_error() {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: this.$t('error.server.ratelimit.title'),
        text: this.$t('error.server.ratelimit.custom.email')
      });
    },
    handle_success(state) {
	    this.submitted = false;
	    this.timeoutResend(120);
      const router = this.$router; // Promise hell...
      this.$swal({
        icon: 'info',
        title: '',
        text: this.$t('setup.email.verify.info'),
        confirmButtonText: this.$t('generic.continue')
      }).then(function() {
        router.push({name: "setup_email_verify"}).catch((error) => {});
      });
    },
    try_submit() {
      if(this.submitted) return;
      this.submitted = true;

      fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
      .then(response => {
        if(response.ok){
          return response.json();
        }
      })
      .then(data => {
        /* Actual request */
        let payload = {
          acsrf_token: data.acsrf_token,
          resend: true
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-email', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              return response.json();
            })
            .then(data => {
              if(!data.status) {
                if(data.error === 'invalid-state') {
                  /* Local state got desynchronized, reevaluating */
                  console.log('Resolving state desynchronization');
                  this.$router.push({name: "login"}).catch((error) => {});
                } else if(data.error == 'rate-limited') this.handle_rate_limit_error();
                  else this.handle_fail_and_reset();
              }
              else this.handle_success(data.followup);
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.handle_fail_and_reset();
            });
        /* --- */
      })
      .catch(error => {
        console.log(error);
        this.handle_fail_and_reset();
      });

    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('setup.email.verify_title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >{{notification.message}}</div>
            <div class="p-2">
              <form @submit.prevent="try_submit">
                <h5 class="text-center">{{$t('setup.email.verify.prompt')}}</h5>
	              <h6 class="text-center text-muted">{{$t('setup.email.verify.details')}}</h6>
                <div class="mt-4 text-center">
                  <b-button type="submit" variant="primary" :class="{'disabled': email_verified_timeout}" :disabled="email_verified_timeout">
	                  <template v-if="email_verified_timeout">
		                  {{$t('setup.email.resend_timeout', {timeout: email_verified_counter})}}
	                  </template>
	                  <template v-else>
		                  {{$t('setup.email.resend')}}
	                  </template>
                  </b-button>
                  <p>
                  <small>
                    <router-link :to="{name: 'setup_email'}">{{$t('setup.email.verify.change')}}</router-link>
                  </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="mt-3 text-center">
      <small>
        © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
        <small style="font-size: 10px;">
          <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
          /
          <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
        </small>
      </small>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
